<template>
  <div class="w-full pt-5 pb-5">
    <template>
      <div class="flex items-center">
        <div class="border-r border-romanSilver h-6 flex mx-2">
          <BackButton
            label="Back"
            @onClick="$router.back()"
            variant="secondary"
          />
        </div>
        <h1 class="text-xl text-left font-extrabold mx-4">
          Moderation Details
        </h1>
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </template>
    <template>
      <template>
        <Card class="p-5 flex flex-col m-5 gap-4">
          <template>
            <div class="flex justify-between items-center">
              <span
                class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
              >
                {{ agreementData.title }}
              </span>
            </div>
          </template>
          <template>
            <div
              class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
            >
              <div
                class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
                @click="showDetails = !showDetails"
              >
                <span
                  class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
                >
                  Performance Details
                </span>
                <span
                  class="w-9/12 border-t border-solid border-romanSilver h-0"
                />
                <Icon
                  :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                  class-name="text-darkPurple mx-auto self-center"
                  size="xms"
                />
              </div>
              <div
                class="flex flex-col gap-2"
                :class="{ hidden: !showDetails }"
              >
                <div class="flex p-3 shadow rounded-md gap-2">
                  <Icon
                    size="s"
                    icon-name="doc-cycle"
                    class-name="text-romanSilver self-start pt-1"
                  />
                  <div class="flex flex-col">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                    >
                      {{ agreementData.cycle }}
                    </span>
                    <span
                      class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                    >
                      {{ agreementData.description }}
                    </span>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div
                    class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                  >
                    <Icon
                      size="xs"
                      icon-name="calendar"
                      class-name="text-romanSilver self-start pt-1"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                      >
                        Period
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{
                          $DATEFORMAT(
                            new Date(agreementData.period.start),
                            "MMMM dd, yyyy"
                          )
                        }}
                        -
                        {{
                          $DATEFORMAT(
                            new Date(agreementData.period.end),
                            "MMMM dd, yyyy"
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                    v-for="(cycle, index) in agreementCycles"
                    :key="index"
                  >
                    <Icon
                      class-name="text-romanSilver self-start pt-1"
                      size="xs"
                      icon-name="reviewicon"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                      >
                        {{ cycle.name }}
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{
                          $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy")
                        }}
                        -
                        {{ $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy") }}
                      </span>
                    </div>
                  </div>
                </div>
                <!----<div class="flex justify-evenly items-center gap-2 w-full">
                  <div class="flex flex-col p-3 shadow rounded-md w-full">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                    >
                      Total Employees
                    </span>
                    <span
                      class="font-semibold leading-5 text-xs tracking-wide text-darkPurple uppercase"
                    >
                      123
                    </span>
                  </div>
                  <div class="flex flex-col p-3 shadow rounded-md w-full">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                    >
                      Number of Submitted
                    </span>
                    <span
                      class="font-semibold leading-5 text-xs tracking-wide text-mediumSeaGreen uppercase"
                    >
                      2
                    </span>
                  </div>
                  <div class="flex flex-col p-3 shadow rounded-md w-full">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                    >
                      Pending
                    </span>
                    <span
                      class="font-semibold leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                    >
                      121
                    </span>
                  </div>
                  <div class="flex flex-col p-3 shadow rounded-md w-full">
                    <span
                      class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                    >
                      Compliance
                    </span>
                    <span
                      class="font-semibold leading-5 text-xs tracking-wide text-desire uppercase"
                    >
                      1.6%
                    </span>
                  </div>
                </div>-->
              </div>
            </div>
          </template>
          <template>
            <div class="flex justify-between items-center gap-10">
              <div class="flex justify-start gap-2">
                <div
                  class="border border-romanSilver rounded-md p-2 flex justify-between items-center gap-2"
                >
                  <Icon
                    class-name="text-romanSilver self-start pt-0"
                    size="xs"
                    icon-name="toolbox"
                  />
                  <div class="flex flex-col gap-0">
                    <span
                      class="font-black text-xs leading-5 uppercase text-darkPurple"
                    >
                      Organization Score
                    </span>
                    <span class="font-semibold text-sm leading-5 uppercase">
                      {{orgScore}}
                    </span>
                  </div>
                </div>
                <div
                  class="border border-romanSilver rounded-md p-2 flex justify-between gap-2"
                >
                  <Icon
                    class-name="text-romanSilver self-start pt-0"
                    size="xs"
                    icon-name="toolbox"
                  />
                  <div class="flex flex-col gap-0">
                    <span
                      class="font-black text-xs leading-5 uppercase text-darkPurple"
                    >
                      employee pre-moderation average
                    </span>
                    <span class="font-semibold text-sm leading-5 uppercase">
                      {{averagePreScore}}
                    </span>
                  </div>
                </div>
                <div
                  class="border border-romanSilver rounded-md p-2 flex justify-between items-center gap-2"
                >
                  <Icon
                    class-name="text-romanSilver self-start pt-0"
                    size="xs"
                    icon-name="toolbox"
                  />
                  <div class="flex flex-col gap-0">
                    <span
                      class="font-black text-xs leading-5 uppercase text-darkPurple"
                    >
                      employee post-moderation average
                    </span>
                    <span class="font-semibold text-sm leading-5 uppercase">
                      {{averagePostScore}}
                    </span>
                  </div>
                </div>
              </div>
              <template>
                <div
                  class="flex justify-center items-center gap-2 cursor-pointer whitespace-nowrap"
                >
                  <Icon
                    class-name="text-flame"
                    size="s"
                    icon-name="icon-share"
                  />
                  <span class="font-normal text-base leading-5 text-flame">
                    Share With Employees
                  </span>
                </div>
              </template>
            </div>
          </template>
        </Card>
      </template>
      <template>
        <div class="w-full py-5 px-5 flex mt-5 flex-col">
          <Tabs
            class="mt-6 mx-5"
            :tabs="tabsItems"
            :active-tab="activeTab"
            @currentTab="activeTab = $event"
            :border="true"
          />
          <div class="w-full flex flex-col">
            <TFunctionalModeration
              v-if="activeTab === 'Functional Moderation'"
              :is-loading="isLoading"
            />
            <TCEOModeration
              v-if="activeTab === 'CEO Moderation'"
              :is-loading="isLoading"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Tabs from "@scelloo/cloudenly-ui/src/components/tab";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import TFunctionalModeration from "./Tabs/TFunctionalModeration";
import TCEOModeration from "./Tabs/TCEOModeration";

export default {
  mixins: [timeFormatMixin],
  components: {
    BackButton,
    Breadcrumb,
    Tabs,
    Card,
    TFunctionalModeration,
    TCEOModeration,
  },
  data() {
    return {
      isLoading: false,
      showDetails: false,
      tabsItems: ["Functional Moderation", "CEO Moderation"],
      activeTab: this.$route.query.activeTab || "Functional Moderation",
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Moderation",
          href: "moderation",
          id: "PerformanceModeration",
        },
        {
          disabled: false,
          text: "Moderation Details",
          href: "moderation-details",
          id: "ModerationDetails",
        },
      ],
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
      },
      agreementCycles: [],
      orgScore: '',
      averagePreScore: '',
      averagePostScore: '---',
    };
  },
  methods: {
    percentageDifference(a, b) {
      return Math.round(100 * Math.abs((a - b) / ((a + b) / 2)) * 100) / 100;
    },
    differenceStatus(a, b) {
      if (a > b) return 1;
      if (a < b) return -1;
      return 0;
    },

    getAgreement() {
      this.$_getOrgPerformanceAgreement().then((response) => {
        const result = response.data.data;
        result.forEach((info) => {
          if (info.agreementDetail.year === Number(this.$route.params.year)) {
            this.agreementData.title =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.cycle =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.description =
              info.agreementDetail.goal_performance_templates.agreement_description;
            this.agreementData.period.start = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .start
            ).toISOString();
            this.agreementData.period.end = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .end
            ).toISOString();
            this.loading = false;
          }
        });
      });
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle().then((response) => {
        const appraisal = response.data.AppraisalCycle;
        this.agreementData.description = appraisal[0].description;
        if (appraisal[0].cycles.length > 0) {
          appraisal[0].cycles.map((cycle) => {
            this.agreementCycles.push({
              name: cycle.name,
              start: this.setDates(cycle.appraisal_starts).toISOString(),
              end: this.setDates(cycle.appraisal_ends).toISOString(),
            });
            return {};
          });
        }
      });
    },
  },

  mounted() {
    this.getAgreement();
    this.getAppraisalCycles();
    this.$_getFunctionalModeration(this.$route.params.year).then(
      (response) => {
        this.orgScore = response.data.orgAppraisalScore;
        this.averagePreScore = response.data.averageEmployeeScore;
      });
  },
};
</script>

<style scoped>
.submitted {
  background: rgba(19, 181, 106, 0.15);
  color: #27ae60;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.not_submitted {
  background: rgba(234, 60, 83, 0.15);
  color: #ea3c53;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
</style>